<template>
  <div v-bind:style="{width:chartWidth}">
    <apexchart ref="chart" :width="chartWidth" :height="chartHeight" :options="chartOptions"
             :series="fromSeriesAndCategory['data']"></apexchart>
  </div>
</template>
<script>

function getChatOptions(category,chartTitle,xTitleArg,yTitleArg,colors){
  let xTitle = {};
  let yTitle = {};
  if(xTitleArg !== undefined){
    xTitle = {
      text: xTitleArg
    }
  }
  if(yTitleArg !== undefined){
    yTitle = {
      text: yTitleArg
    }
  }

  return {
    chart: {
      type: 'bar',
      animations: {
        enabled: false
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,// pan: true,// reset: true,// customIcons: []
        }
      },
    },
    fill:{
      colors:colors
    },
      animations: {enabled: false,},
      plotOptions: {
        bar: {
          horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
            width: 2,
            colors: ['transparent']
      },
    xaxis: {
      // categories: ['10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30'],
      tickAmount: category.length > 10 ? 10 :category.length,
      categories: category,
      title:xTitle,
    },
    yaxis:{
      // min:ymin,
      // max:ymax,
      title:yTitle,
      tickAmount:5,
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    },
    title: {
      text: chartTitle,
      align: 'center',
      offsetX: 0,
      offsetY: 5,
      floating: true,
      style: {
        fontSize: '17px',
        fontWeight: 'bold',
        // fontFamily:  undefined,
        color: '#263238'
      },
    },
      tooltip: {
        y: {
          formatter: function (val) {
            return val+"回";
          }
        }
      }
  };
}
//spo2
export default {
  name: "BreathColumn",
  props: {
    dayCount: {
      required: true
    },
    tickAmount: {type:Number},
    // ymin:{type:Number,default:0},
    // ymax:{type:Number,default:300},
    // series: {type: Array, required: false},
    fromDate: {},
    chartTitle: {default: "SpO2", String},
    chartHeight: {default: "350"},
    chartWidth: {default: "100%"},
    fromSeriesAndCategory:{type:Object},
    xTitle:{type:String},
    yTitle:{type:String},
    colors:{type:Array}

  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
    }
  },
  watch:{
    fromSeriesAndCategory(){
      this.chartOptions = getChatOptions(this.fromSeriesAndCategory["category"],this.chartTitle/*,this.ymin,this.ymax*/,this.xTitle,this.yTitle,this.colors);
    }
  },
  data() {
    return {
      // series: [{
      //   data: [10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
      // }],
      chartOptions:getChatOptions(this.fromSeriesAndCategory["category"],this.chartTitle/*,this.ymin,this.ymax*/,this.xTitle,this.yTitle,this.colors)
    }
  },
}
;
</script>
<style>
</style>
