<template>
  <div v-bind:style="{width:chartWidth}">
  <apexchart ref="chart" :width="chartWidth" :height="chartHeight" :options="chartOptions" :series="fromSeriesLables['series']"></apexchart>
  </div>
</template>
<script>

// import moment from "moment";
//spo2

function getOptions(chartTitle,labels){


  let colorList = {
    // "0.00":"#e0c7c7",
    // "0.25":"#BE1F05",
    // "0.50":"#F60003",
    // "0.75":"#F64E09",
    // "1.00":"#F9C308",
    // "1.25":"#FAFF09",
    // "1.50":"#C3FF28",
    // "1.75":"#5BFF27",
    // "2.00":"#2BFF87",
    // "2.50":"#31C0FF",
    // "3.00":"#274EC1",
    // "3.50":"#2701FF",
    // "4.00":"#8547FF",
    // "4.50":"#8500FF",
    // "5.00":"#520053",
    // "9.99":"#e2d5d5",

    "0.25":"#DCDC80",
    "0.50":"#F0ED83",
    "0.75":"#98B54F",
    "1.00":"#66FF33",
    "1.25":"#00FF99",
    "1.50":"#00B050",
    "1.75":"#00B0B0",
    "2.00":"#3366CC",
    "2.50":"#0000FF",
    "3.00":"#9966FF",
    "3.50":"#9900FF",
    "4.00":"#FFC000",
    "4.50":"#FF962A",
    "5.00":"#FF9999",
  };

  let colors = labels.map(function(value){
    return colorList[value]
  })

  return {
    chart: {
      type: 'pie',
      animations:{
        enabled:false
      },
    },
    dataLabels: {
      enabled: true,
    },
    animations: {enabled: false,},
    legend:{
      fontSize: '12px',
      horizontalAlign: 'center',
      markers: {
        radius: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: -3
      },
    },
    colors:colors,
    title: {
      text: chartTitle+ ' 単位：リットル／分',
      align: 'center',
      offsetX: -30,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '17px',
        fontWeight:  'bold',
        // fontFamily:  undefined,
        color:  '#263238'
      },
    },
    // labels: ["0.25","0.50","0.75","1.00","1.25","1.50","1.75","2.00","2.50","3.00","3.50","4.00","4.50","5.00"]
    labels: labels
  };
}


export default {
  name: "BreathPie",
  props: {
    dayCount: {
      required: true
    },
    // series: {type: Array, required: false},
    fromDate: {},
    chartTitle: {default: "SpO2", String},
    chartHeight:{default:"350"},
    chartWidth:{default:"98%"},
    fromSeriesLables:{type:Object}
  },
  watch:{
    fromSeriesLables(){
      this.chartOptions = getOptions(this.chartTitle,this.fromSeriesLables["labels"])
    }
  },
  data() {
    return {
      // series: [1,4,8,7,7,3,6,9,12,22,10,20,23,23],
      // series: this.fromSeriesLables["series"],
      chartOptions: getOptions(this.chartTitle, [])
    }
  }
};
</script>
<style>
</style>
