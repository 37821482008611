var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { width: _vm.chartWidth } },
    [
      _c("apexchart", {
        ref: "chart",
        attrs: {
          width: _vm.chartWidth,
          height: _vm.chartHeight,
          options: _vm.chartOptions,
          series: _vm.fromSeriesLables["series"]
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }